import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [2,4,5];

export const dictionary = {
		"/(marketing)": [11,[3]],
		"/(auth)/auth/login": [7,[2]],
		"/(auth)/auth/logout": [8,[2]],
		"/(auth)/auth/register": [9,[2]],
		"/(auth)/auth/reset": [10,[2]],
		"/(marketing)/features": [12,[3]],
		"/(my)/my/dashboard": [~15,[4]],
		"/(my)/my/deals": [~16,[4]],
		"/(my)/my/deals/[id]": [~17,[4]],
		"/(my)/my/settings": [~18,[4,5]],
		"/(my)/my/settings/account": [~19,[4,5]],
		"/(my)/my/settings/profile": [~20,[4,5]],
		"/(my)/my/settings/security": [~21,[4,5]],
		"/(marketing)/pricing": [13,[3]],
		"/(marketing)/releases": [14,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';